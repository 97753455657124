/**
 * Action types
 */
export enum SettingsTypes {
  SET_CONFIGS = '@settings/SET_CONFIGS',
  SET_LOADING_CONFIGS = '@settings/SET_LOADING_CONFIGS',
  SET_BASE_SETTINGS = '@settings/SET_BASE_SETTINGS',
  SET_WEBAPP_ENABLED = '@settings/SET_WEBAPP_ENABLED',
}

/**
 * Data Types
 */
export interface ISetting {
  base: ISettingBase;
  configs: ISettingConfig;
}

/**
 * @summary base64 vindo dessa forma:
 * @example order_id;company_id;shop_id
 */
export interface ISettingBase {
  shopId: number | null;
  companyId: number | null;
  orderId: number | null;
}

export interface ISettingConfig {
  id: number | null;
  color: string;
  footer: IFooter;
  bg_color: string;
  logo: string | null;
  company_id: number | null;
  company_name: string;
  default_shop: number | null;
  has_booklet: boolean;
  active_shops: number[];
  has_cart_alert: boolean;
  navbar_dropdown_label: string | null;
  register_enabled: boolean;
  chosen_templates: ITemplate[];
  advantage_club: IAdvantageClub;
  has_min_quantity_alert: boolean;
  min_quantity_alert: number | null;
  identification_type: IdentificationType;
  is_enabled: boolean;
  search_filters: IFilter;
  client_cart_edit_outside_time: boolean;
  client_finish_order_outside_time: boolean;
  ask_cpf: boolean;
  ask_cpf_question: string;
  ask_cpf_description: string;
  ask_zipcode: boolean;
  default_color: string;
  cookies_popup_message: string;
  show_wholesales_price: boolean;
  use_clients_wholesales: boolean;
  unique_payment: boolean;
  free_shipping_tag_color: string;
  navbar_footer_color: string;
  cpf_required: boolean;
  buy_button_color: string;
}

interface IAdvantageClub {
  text: string | null;
  bg_color: string | null;
}

interface IFilter {
  brand: boolean;
  category: boolean;
  group: boolean;
  price: boolean;
  size: boolean;
}

type IdentificationType = 'phone' | 'email';

interface IFooter {
  bg_color: string;
  links: ILink[];
  networks: INetwork[];
  payments: IPayment[];
  shipments: IShipment[];
  banner: {
    bg_color: string;
    image_mobile: string | null;
    image_desktop: string | null;
  };
}

interface ILink {
  link_id: number;
  title: string;
  link: string;
}

export interface INetwork {
  color_type: string;
  type: NetworkType;
  link: string;
  image: string;
}

type NetworkType =
  | 'whatsapp'
  | 'facebook'
  | 'instagram'
  | 'twitter'
  | 'youtube'
  | 'linkedin'
  | 'telegram';

export type FooterImageList = {
  type: string;
  image: string;
};

interface IPayment extends FooterImageList {}
interface IShipment extends FooterImageList {}

export interface ITemplate {
  id: number;
  status: boolean;
  webapp_id: number;
  world_id: number;
  created_at: string;
  updated_at: string;
  page_name: PageName;
  template_desc: string;
  image_groups: IImageGroup[];
  top_alert_message: ITopAlertMessage | null;
  product_lists: ITemplateProductList[] | null;
  template_name: ThemeDefault | ThemeFashionStore1 | ThemeDistributorStore1;
  has_brand_filter: boolean;
}

export interface ITemplateProductList {
  title: string;
  position: number;
  limit: number | null;
  type: ProductListRouteType;
  category_id?: number;
  group_id?: number;
}

export type ProductListRouteType =
  | 'best-sales'
  | 'offers'
  | 'random-offers'
  | 'offers-for-you'
  | 'super-offers'
  | 'gift-club'
  | 'group'
  | 'category';

interface ITopAlertMessage {
  bg_color: string;
  text: string | null;
}

export type PageName =
  | 'home'
  | 'category'
  | 'product'
  | 'cart'
  | 'world'
  | '404'
  | 'booklet'
  | 'error'
  | 'privacy-policy';

export interface IImageGroup {
  name: string;
  images: IImage[];
}

export interface IImage {
  id: number;
  link: string;
  locate: string;
  status: boolean;
  shop_template_id: number;
  description: string | null;
  redirect_type: 'custom' | 'category' | 'product' | 'world';
  redirect_link: string | null;
  created_at: string;
  updated_at: string;
  redirect_item_name: string | null;
  has_promotion: boolean;
}

type ThemeDefault =
  | 'home-default'
  | 'product-default'
  | 'category-default'
  | '404-default'
  | 'cart-default';
type ThemeFashionStore1 = 'home-fashion-1' | 'product-fashion-1' | 'category-fashion-1';
type ThemeDistributorStore1 = 'home-distributor-1' | 'category-distributor-1';

/**
 * State type
 */
export interface ISettingsState {
  readonly base: ISettingBase;
  readonly configs: ISettingConfig;
}
