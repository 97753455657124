import './default.scss';

import { lazy, Suspense, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import PaidAlertModal from '@/resources/components/cart/paidAlertModal/PaidAlertModal';
import ShippingDiscountModal from '@/resources/components/cart/shipment/address/components/delivery/shippingDiscountModal/ShippingDiscountModal';
import ListProductsLoad from '@/resources/components/loads/ListProductsLoad';
import { useViewCart } from '@/resources/hooks/useViewCart';
import { getShippingDiscounts } from '@/routes/integrations';
import { getFreeShippingProducts } from '@/routes/products';
import { ApplicationState } from '@/store';
import { IPaymentAlertAsks } from '@/store/ducks/payment/types';
import { IProductList } from '@/store/ducks/productLists/types';

import {
  canChangeOrder,
  canFinishOrder,
  getOrderMinValue,
  maskMoney,
  OrderStatus,
} from '../../../../services/global';
import * as PaymentActions from '../../../../store/ducks/payment/actions';
import CartResumeCard from '../../../components/cart/cartResumeCard/CartResumeCard';
import DeleteAllCart from '../../../components/cart/reviewItemModal/DeleteAllCart';
import SendOrderAlert from '../../../components/cart/sendOrderAlert/SendOrderAlert';
import Shipment from '../../../components/cart/shipment/Shipment';
import StatusSteps from '../../../components/cart/statusSteps/StatusSteps';
import Subtotal from '../../../components/cart/subtotal/Subtotal';
import Breadcrumb from '../../../components/global/breadcrumb/Breadcrumb';
import CartPageLoad from '../../../components/loads/CartPageLoad';
import { usePageBreakpoints } from '../../../hooks/usePageBreakpoints';
import { useSmartTextColor } from '../../../hooks/useSmartTextColor';
import { useTextColor } from '../../../hooks/useTextColor';
import { useWebContext } from '../../../hooks/useWebContext';

const Products = lazy(() => import('@/resources/components/products/Products'));

function Default() {
  const color = useSmartTextColor();
  const listColor = useSmartTextColor('#666');
  const dispatch = useDispatch();

  const { breakpoints } = usePageBreakpoints();
  const { order, configs, setShowIsCartBlockedModal } = useWebContext();
  const textColor = useTextColor(configs.color);

  const [shipmentStep, setShipmentStep] = useState<1 | 2 | 3 | 4>(1);
  const [showSendOrderAlert, setShowSendOrderAlert] = useState<boolean>(false);
  const [showCartDeliveryAlert, setShowCartDeliveryAlert] = useState<boolean>(false);
  const [showShippingDiscount, setShowShippingDiscount] = useState<boolean>(false);

  const freeShippingProducts = useSelector<ApplicationState, IProductList>(
    (state) => state.productLists.freeShipping,
  );

  const paymentAlertAsks = useSelector<ApplicationState, IPaymentAlertAsks>(
    (state) => state.payment.asks,
  );

  const shipmentCardStyle = {
    backgroundColor: configs.color,
    borderColor: configs.color,
    color: textColor,
  };

  const handleCloseSendOrderAlert = () => setShowSendOrderAlert(false);

  const handleFinishOrder = () => {
    const { isValid: orderCanBeChanged, showIsCartBlockedModal } = canChangeOrder({
      type: 'pedido',
      action: 'finalizado',
    });

    if (order?.status !== OrderStatus.OPEN && order?.status !== OrderStatus.ABANDONED) {
      toast(`Encaminhamos seu pedido para a finalização com vendedor por WhatsApp 🙂`, {
        type: 'success',
        autoClose: 4000,
      });

      return false;
    }

    if (orderCanBeChanged && canFinishOrder(shipmentStep)) return true;

    if (showIsCartBlockedModal) setShowIsCartBlockedModal(true);

    return false;
  };

  useEffect(() => {
    if (!order?.shipment) return;

    if (Number(order?.cart.items_total) < getOrderMinValue(order)) {
      setShowCartDeliveryAlert(true);
    } else {
      setShowCartDeliveryAlert(false);
    }
  }, [order?.cart.items_total, order?.shipment?.delivery?.id, order?.dst_shop_id]);

  useEffect(() => {
    if (!order?.cart || !order?.cart.products?.length) return;

    useViewCart(order.cart);
  }, [order?.id]);

  useEffect(() => {
    if (!order) return;

    const validPayments = order.payments?.filter((payment) => !payment.deleted_at);
    const isPaid = validPayments?.length ? validPayments?.every((p) => p.status === 'paid') : false;

    const hasToShowModal = validPayments && validPayments.length > 0 && isPaid;

    if (!paymentAlertAsks.has_shown_paid_alert && hasToShowModal) {
      dispatch(PaymentActions.setShowPaidAlert(true));
    }

    getShippingDiscounts(order.dst_shop_id);
  }, [order?.id, order?.dst_shop_id]);

  useEffect(() => {
    if (!order) return;

    getShippingDiscounts(order.dst_shop_id);
  }, []);

  useEffect(() => {
    if (!order || (!order?.cart.delivery_tax && order.shipment.type === 'delivery')) return;

    getFreeShippingProducts(order.dst_shop_id);
  }, [order?.id, order?.dst_shop_id, order?.cart.delivery_tax]);

  useEffect(() => {
    if (!order) return;

    if (!order?.cart.delivery_tax) return;

    getFreeShippingProducts(order.dst_shop_id);
  }, []);

  return (
    <section className="Cart pb-3">
      <Container fluid>
        <Row className="justify-content-center">
          <Col {...breakpoints} className="breakpoint-page">
            <Breadcrumb page="cart" />
            {order === null ? (
              <CartPageLoad />
            ) : (
              <>
                {order && order.cart.products.length > 0 ? (
                  <>
                    {order.status !== OrderStatus.OPEN &&
                      order.status !== OrderStatus.ABANDONED && (
                        <Row className="justify-content-center my-2">
                          <Col xs={12} md={10} lg={8}>
                            <StatusSteps order={order} />
                          </Col>
                        </Row>
                      )}

                    <Row>
                      <Col xs={12} md={8}>
                        <Card className="shipment-card mb-3 mb-sm-2">
                          <Card.Header style={shipmentCardStyle}>Meu carrinho</Card.Header>
                          <Card.Body>
                            {showCartDeliveryAlert && (
                              <div className="d-flex align-items-center justify-content-center bg-light">
                                <span className="py-2">
                                  O valor mínimo do total pedido é de
                                  <strong className="ml-1">
                                    {maskMoney(getOrderMinValue(order), true)}
                                  </strong>
                                </span>
                              </div>
                            )}

                            <ul className="list-unstyled mb-0 w-100">
                              {order.cart.products.map((product) => {
                                return (
                                  product && (
                                    <CartResumeCard
                                      item={product}
                                      key={`cart-item-${product.product_id}-${product.ean}`}
                                    />
                                  )
                                );
                              })}
                            </ul>

                            <div className="d-flex justify-content-end">
                              <DeleteAllCart />
                            </div>

                            <hr className="mb-2 mt-1" />

                            <Subtotal subtotal cart={order.cart} />
                          </Card.Body>
                        </Card>

                        {order.status === 1 && !order.is_paid && (
                          <div className="text-center mb-4">
                            <Link
                              to={'/w'}
                              className="btn btn-sm btn-success add-more-items"
                              style={{ borderRadius: '10px' }}
                            >
                              Adicionar mais itens
                            </Link>
                          </div>
                        )}
                      </Col>

                      <Col xs={12} md={4}>
                        <Shipment
                          order={order}
                          setShipmentStep={setShipmentStep}
                          handleFinishOrder={handleFinishOrder}
                          forwardedOrderAlert={setShowSendOrderAlert}
                        />
                      </Col>

                      <Col xs={12}>
                        <SendOrderAlert
                          show={showSendOrderAlert}
                          handleClose={handleCloseSendOrderAlert}
                          socialNetworkType={order.client.social_network_type}
                        />
                      </Col>
                      <Col xs={12}>
                        <>
                          {!!(!!freeShippingProducts.loading && order?.cart.delivery_tax) && (
                            <ListProductsLoad />
                          )}

                          {!freeShippingProducts.loading &&
                          freeShippingProducts.products.length > 0 ? (
                            <section>
                              <div
                                className="section-header"
                                style={{ lineHeight: 1, color: listColor }}
                              >
                                <h1 className="mb-0 section-header border-0">
                                  Quer ganhar FRETE GRÁTIS?
                                </h1>
                                <div
                                  className="font-weight-normal d-flex align-items-baseline"
                                  style={{ fontSize: '0.8rem' }}
                                >
                                  <span>
                                    Adicione um dos itens abaixo e ganhe frete grátis, consulte os
                                    regulamentos
                                  </span>
                                  <button
                                    className="btn btn-sm text-primary p-0"
                                    style={{ fontSize: '0.8rem', marginLeft: '0.1rem' }}
                                    onClick={() => setShowShippingDiscount(true)}
                                  >
                                    aqui
                                  </button>
                                  !
                                </div>

                                <ShippingDiscountModal
                                  show={showShippingDiscount}
                                  setShow={setShowShippingDiscount}
                                  showDiscounts={false}
                                />
                              </div>

                              <Suspense fallback={<div />}>
                                <Products products={freeShippingProducts.products} keyName="free" />
                              </Suspense>
                            </section>
                          ) : null}

                          <PaidAlertModal />
                        </>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <div className="text-center mt-2">
                      <h4 style={{ color }}>Oops! Carrinho está vazio</h4>
                      <Link to={'/w'} className="btn btn-info">
                        Adicionar items
                      </Link>
                    </div>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Default;
