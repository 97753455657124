import './payment.scss';

import { lazy, Suspense, useEffect, useState } from 'react';
import { Badge, Modal } from 'react-bootstrap';

import { useShipmentContext } from '@/resources/components/cart/shipment/Shipment';

import { getOrderMinValue, translatePaymentMethod } from '../../../../../services/global';
import { subscribeToPayment } from '../../../../../services/socket';
import { IOrder } from '../../../../../store/ducks/order/types';
import { useFormatMoney } from '../../../../hooks/useFormatMoney';
import OrderResume from '../../orderResume/OrderResume';
import Subtotal from '../../subtotal/Subtotal';
import { ShipmentCard } from '../shipmentCard/ShipmentCard';
import PaymentButton from './PaymentButton';

const PaymentPlansModal = lazy(() => import('./components/PaymentPlansModal'));

interface Props {
  order: IOrder;
}

function Payment({ order }: Props) {
  const { step, setStep } = useShipmentContext();
  const { id: orderId, cart, total } = order;

  const totalPaid = useFormatMoney(total ?? 0);

  const [show, setShow] = useState<boolean>(false);
  const [showConfirmedPaymentModal, setShowConfirmedPaymentModal] = useState<boolean>(false);

  const isAllItemsAvaliable = order.cart.products.every((item) => item.is_available === true);
  const validPayments = order?.payments?.filter((p) => !p.deleted_at);
  const canFinishUnpaid = validPayments?.every((p) => p.integration?.data?.can_finish_unpaid);

  useEffect(() => {
    const hasPaymentSeted = validPayments?.some((p) => p.id && p.payment_plan_id);
    if (step === 3 && hasPaymentSeted) setStep(4);
  }, [validPayments, step, setStep]);

  useEffect(() => {
    if (orderId) {
      subscribeToPayment({
        orderId,
        callback: (order) => {
          if (order.is_paid) setShowConfirmedPaymentModal(true);
        },
      });
    }
  }, [orderId]);

  const [validUniquePayment, setValidUniquePayment] = useState<boolean>(false);

  return (
    <>
      <ShipmentCard
        disabled={step <= 2 || !order.shipment.pickup_at}
        showModal={show}
        setShowModal={setShow}
        warningType="pagamento"
        isPaid={
          validPayments &&
          validPayments.length > 0 &&
          validPayments.every((p) => p.status === 'paid')
        }
      >
        <ShipmentCard.Content
          title="3 - Pagamento"
          actions={
            !!order.payments?.length &&
            order.cart.items_total > getOrderMinValue(order) &&
            !validUniquePayment
          }
          footer={
            <>
              <hr className="my-1" />
              <OrderResume order={order} />
              <Subtotal cart={cart} />

              {isAllItemsAvaliable && (
                <PaymentButton setShow={setShow} setValidUniquePayment={setValidUniquePayment} />
              )}

              {!isAllItemsAvaliable && (
                <p className="payment-availability">
                  *Não é possível finalizar um pedido com um produto indisponível, por gentileza,
                  remova-o do carrinho.
                </p>
              )}
            </>
          }
        >
          {validPayments && validPayments.length > 0 && (
            <div>
              {validPayments.map((payment) => {
                return (
                  <div
                    className="d-flex flex-column align-items-start payment-method"
                    key={payment.id}
                  >
                    <p className="mb-0">
                      <span>
                        <strong>Forma: </strong>
                        <span>{translatePaymentMethod(payment.payment_method)}</span>
                      </span>

                      {payment.status === 'paid' && (
                        <Badge variant="success" className="ml-1">
                          Pago
                        </Badge>
                      )}
                    </p>

                    <p className="mb-0">
                      <span>
                        <strong>Prazo: </strong>
                        <span>{payment.payment_plan_name}</span>
                      </span>
                    </p>

                    {!validPayments?.every((p) => p.status === 'paid') && (
                      <small className={`badge badge-${canFinishUnpaid ? 'secondary' : 'warning'}`}>
                        {canFinishUnpaid
                          ? '(pagamento efetuado na entrega)'
                          : 'aguardando pagamento'}
                      </small>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </ShipmentCard.Content>

        <Suspense fallback={<div />}>
          <PaymentPlansModal show={show} setShow={setShow} />
        </Suspense>
      </ShipmentCard>

      <Modal
        centered
        animation={false}
        show={showConfirmedPaymentModal}
        onHide={() => setShowConfirmedPaymentModal(false)}
      >
        <Modal.Header
          closeButton
          style={{ position: 'absolute', right: '1rem', top: '1rem', zIndex: 2 }}
          className="border-0 p-0"
        ></Modal.Header>
        <Modal.Body className="border-rounded text-center">
          <p className="mb-0">
            Pagamento de
            <small style={{ fontSize: '10pt' }}> R$</small>{' '}
            <span className="badge badge-success" style={{ fontSize: '13pt' }}>
              {totalPaid}
            </span>
          </p>
          <h4 className="font-weight-bold">confirmado</h4>

          <h5 className="mb-0">Obrigado!</h5>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Payment;
