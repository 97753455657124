import './deliveryInformation.scss';

import { lazy, Suspense, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

import { useWebContext } from '@/resources/hooks/useWebContext';
import { maskMoney } from '@/services/global';
import { IAttendanceType } from '@/store/ducks/order/types';

import RenderPickupAt from '../RenderPickupAt';
import AppliedDiscountModal from './shippingDiscountModal/AppliedDiscountModal';

const DeliveryMethodsModal = lazy(() => import('./DeliveryMethodsModal'));

function DeliveryInformation() {
  const [showMethods, setShowMethods] = useState<boolean>(false);
  const [showDiscount, setShowDiscount] = useState<boolean>(false);

  const { order } = useWebContext();
  const { shipment } = order || {};

  const getDeliveryInfoByType = (type: string) => {
    const types = shipment?.attendance_types || {};

    if (!shipment?.attendance_types || !(type in types)) return null;

    return shipment?.attendance_types[type as keyof IAttendanceType];
  };

  const getDeliveryInfo = () => {
    const shipmentType = shipment?.delivery?.type || '';
    const shipmentInfo = getDeliveryInfoByType(shipmentType);

    return shipmentInfo;
  };

  const deliveryInfo = getDeliveryInfo();
  const value = order?.cart.delivery_tax ? maskMoney(order?.cart.delivery_tax, true) : null;
  const hasDeliveryDiscount = order?.shipment?.delivery_discount;

  const getDeliveryName = () => {
    const name = shipment?.full_name_carrier ?? shipment?.carrier;

    return name || 'Padrão';
  };

  return (
    <>
      {deliveryInfo && (
        <>
          <div className="delivery-information">
            <div className="method">
              <span className="title d-flex align-items-center">
                Frete {getDeliveryName()}
                {hasDeliveryDiscount && (
                  <>
                    <button
                      className="btn btn-sm badge text-white badge-success ml-1"
                      onClick={() => setShowDiscount(true)}
                    >
                      {hasDeliveryDiscount.type_discount === 'free' ? 'frete grátis' : 'desconto'}
                      <FaInfoCircle className="ml-1" />
                    </button>

                    <AppliedDiscountModal
                      show={showDiscount}
                      setShow={setShowDiscount}
                      deliveryDiscount={hasDeliveryDiscount}
                    />
                  </>
                )}
              </span>
              <small className="subtitle action" onClick={() => setShowMethods(true)}>
                Ver outras opções de entrega
              </small>
            </div>
            <div className="infos">
              <span className="title price">{value || 'R$ 0,00'}</span>
              <small className="subtitle">
                Entrega:
                {shipment?.pickup_at ? (
                  <RenderPickupAt
                    pickupAt={shipment.pickup_at}
                    showTime={shipment?.delivery?.type === 'google-maps'}
                  />
                ) : (
                  'A definir'
                )}
              </small>
            </div>
          </div>

          <Suspense fallback={<div />}>
            <DeliveryMethodsModal show={showMethods} setShow={setShowMethods} />
          </Suspense>
        </>
      )}

      {!deliveryInfo && !shipment?.refresh_carrier && (
        <div className="without-method">
          <span className="warning-text text-center">Endereço fora da área de cobertura</span>
        </div>
      )}
    </>
  );
}

export default DeliveryInformation;
