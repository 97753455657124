import './cartResumeCard.scss';

import { lazy, Suspense } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useProductLink } from '@/resources/hooks/useProductLink';

import { ICartProduct } from '../../../../store/ducks/order/types';
import BgImage from '../../global/Image/BgImage';
import Description from '../../product/description/Description';
import FreeShippingInLine from '../../product/freeShippingAlert/FreeShippingInLine';
import Price from '../../product/price/Price';
import ListSelectedOptions from '../listSelectedOptions/ListSelectedOptions';
import ListSpecs from '../listSpecs/ListSpecs';

const SaleForm = lazy(() => import('../../global/saleForm.tsx/SaleForm'));

interface Props {
  item: ICartProduct;
}

function CartResumeCard({ item }: Props) {
  const productWeight = item.see_as_unit ? item.average_weight : 1;
  const hasFreeShipping = item?.delivery_discount_data?.type_discount === 'free';
  const link = useProductLink({ product: item });

  return (
    <li className="cart-item-card cursor-pointer">
      <Link to={link} className="text-decoration-none">
        <Container fluid className="">
          <Row>
            <Col xs={4} md={2} className="text-center my-auto pr-0">
              <div className="image-wrapper">
                <BgImage src={item.image} />
              </div>
            </Col>

            <Col xs={8} md={6} lg={7} className="py-1 py-md-0">
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="d-flex flex-row mb-2">
                  <div className="mb-1 w-100">
                    <h1 className="title">{item.title}</h1>

                    <ListSelectedOptions options={item.options} />
                    <ListSpecs data={item.data} />

                    {!item.is_available && (
                      <p className="availability">*Produto não disponível na loja selecionada.</p>
                    )}
                  </div>
                </div>

                {hasFreeShipping && (
                  <FreeShippingInLine classes="free-shipping-cart" text="FRETE GRÁTIS" />
                )}

                <Description description={item.description} limit limitNumber={2} />
              </div>
            </Col>

            <Col xs={12} md={4} lg={3} className="py-1 py-md-0 mt-auto">
              <div className="d-flex d-md-block flex-row-reverse">
                {item.is_available && (
                  <Price
                    price={item.subtotal}
                    priceStyles={{ flexDirection: 'column', alignItems: 'flex-end' }}
                    defaultPrice={parseFloat(
                      (item.default_price * item.quantity * productWeight).toFixed(2),
                    )}
                  />
                )}

                <Suspense fallback={<div />}>
                  <SaleForm
                    fullWidth
                    product={item}
                    currentMeasure={item.measure}
                    btnStyles={{ padding: '6px .75rem' }}
                    formClasses="mr-2 mr-md-0"
                    styles={{
                      left: 0,
                      bottom: 0,
                      padding: '0',
                      marginTop: '5px',
                      position: 'relative',
                    }}
                  />
                </Suspense>
              </div>
            </Col>
          </Row>
        </Container>
      </Link>
    </li>
  );
}

export default CartResumeCard;
