import { Reducer } from 'redux';

import { ISettingsState, SettingsTypes } from './types';

const DEFAULT_STATE: ISettingsState = {
  base: {
    orderId: null,
    shopId: null,
    companyId: null,
  },
  configs: {
    id: null,
    logo: null,
    color: '#fff',
    navbar_footer_color: '#fff',
    company_id: null,
    ask_cpf: false,
    ask_cpf_question: '',
    ask_cpf_description: '',
    ask_zipcode: false,
    company_name: '',
    active_shops: [],
    default_shop: null,
    bg_color: '#ebebeb',
    default_color: '#fff',
    cookies_popup_message: '',
    has_booklet: false,
    has_cart_alert: false,
    navbar_dropdown_label: null,
    register_enabled: true,
    identification_type: 'phone',
    is_enabled: true,
    show_wholesales_price: false,
    use_clients_wholesales: false,
    client_cart_edit_outside_time: false,
    client_finish_order_outside_time: false,
    advantage_club: {
      text: null,
      bg_color: '#fff',
    },
    min_quantity_alert: null,
    has_min_quantity_alert: false,
    footer: {
      bg_color: '#fff',
      links: [],
      networks: [],
      payments: [],
      shipments: [],
      banner: {
        bg_color: '#fff',
        image_mobile: null,
        image_desktop: null,
      },
    },
    chosen_templates: [],
    search_filters: {
      brand: false,
      category: false,
      group: false,
      price: false,
      size: false,
    },
    unique_payment: false,
    free_shipping_tag_color: '#fff',
    buy_button_color: '#fff',
    cpf_required: false,
  },
};

const reducer: Reducer<ISettingsState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SettingsTypes.SET_BASE_SETTINGS:
      return { ...state, base: action.payload };
    case SettingsTypes.SET_CONFIGS:
      return { ...state, configs: action.payload };
    case SettingsTypes.SET_WEBAPP_ENABLED:
      state.configs.is_enabled = action.payload;

      return { ...state, configs: state.configs };
    default:
      return state;
  }
};

export default reducer;
