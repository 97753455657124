import { memo, useEffect, useState } from 'react';
import { FaTimesCircle } from 'react-icons/fa';

import { PriceInterval } from '@/resources/components/filters/searchFilter/SearchFilter';
import { useWebContext } from '@/resources/hooks/useWebContext';
import { isLightOrDark } from '@/services/lightOrDark';
import { IPriceFilter } from '@/store/ducks/searches/types';

interface Props {
  price: IPriceFilter;
  index: number;
  priceInterval: PriceInterval;
  setPriceInterval: (priceInterval: PriceInterval) => void;
}

function ShortcutItem({ price, priceInterval, setPriceInterval, index }: Props) {
  const { configs } = useWebContext();

  const bgType = isLightOrDark(configs.bg_color);
  const min = priceInterval?.minPrice;
  const max = priceInterval?.maxPrice;
  const [active, setActive] = useState<string>('');

  const getShortCutPrice = (index: number) => {
    return {
      0: `Até R$${price.max_price} (${price.count})`,
      1: `R$${price.min_price} a R$${price.max_price} (${price.count})`,
      2: `Mais de R$${price.min_price} (${price.count})`,
    }[index];
  };

  useEffect(
    () => setActive(min === price.min_price && max === price.max_price ? 'active' : ''),
    [min, max, price.min_price, price.max_price],
  );

  return (
    <>
      {price.count > 0 && (
        <span
          className={`price-shortcut bg-color-${bgType} ${active}`}
          onClick={() => {
            if (!active) setPriceInterval({ minPrice: price.min_price, maxPrice: price.max_price });
          }}
        >
          {getShortCutPrice(index)}
          {active && (
            <FaTimesCircle
              className="ml-2"
              onClick={() => {
                setPriceInterval({ minPrice: undefined, maxPrice: undefined });
              }}
            />
          )}
        </span>
      )}
    </>
  );
}
export default memo(ShortcutItem);
