import { useSelector } from 'react-redux';

import { IShopsAddress } from '@/store/ducks/shops/types';

import { formatAddressNumber, getFullAddress } from '../../../../../../services/global';
import { ApplicationState } from '../../../../../../store';
import { IAddress, IShipmentType, IShopAddress } from '../../../../../../store/ducks/order/types';
import RenderPickupAt from './RenderPickupAt';

interface Props {
  shopNameDst: string;
  type: IShipmentType | null;
  address: IAddress | IShopAddress;
  pickupAt: string | null;
}

function RenderOrderAddress({ shopNameDst, type, address, pickupAt }: Props) {
  const selectShops = useSelector<ApplicationState, IShopsAddress[]>((state) => state.shops.data);
  const selectedShop = selectShops.find((shop) => shop.id === address.id);

  return (
    <div className="shipment-address flex-column">
      {type === 'shop' ? (
        <>
          <div className="d-flex flex-row align-items-baseline">
            <h6 className="mb-1 mr-1">Loja:</h6>
            <span style={{ fontSize: '10pt' }}>{shopNameDst}</span>
          </div>

          {selectedShop && (
            <div className="d-flex flex-row align-items-baseline">
              <h6 className="mb-1 mr-1">Endereço:</h6>
              <span className="text-break" style={{ fontSize: '10pt' }}>
                {getFullAddress(selectedShop.address)}
              </span>
            </div>
          )}

          <div className="d-flex flex-row align-items-baseline">
            <h6 className="mb-1 mr-1">Horário:</h6>
            <span style={{ fontSize: '10pt' }}>
              <RenderPickupAt pickupAt={pickupAt} />
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex flex-row align-items-baseline">
            <h6 className="mb-1 mr-1">Endereço:</h6>
            <span className="text-break" style={{ fontSize: '10pt' }}>
              {(address as IAddress).street}, {formatAddressNumber((address as IAddress).number)} -{' '}
              {(address as IAddress).city} / {(address as IAddress).state}
            </span>
          </div>
        </>
      )}
    </div>
  );
}

export default RenderOrderAddress;
