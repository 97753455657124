import {
  Children,
  CSSProperties,
  forwardRef,
  ReactNode,
  Ref,
  SyntheticEvent,
  useState,
} from 'react';
import { Dropdown } from 'react-bootstrap';
import { BiMenuAltLeft } from 'react-icons/bi';

import { useDevice } from '@/resources/hooks/useDevice';
import { useTextColor } from '@/resources/hooks/useTextColor';

import { useNavbarContext } from '../../hooks/useNavbarContext';
import { useWebContext } from '../../hooks/useWebContext';
import BookletsLink from './BookletsLink';
import CategoryLink from './CategoryLink';

interface CustomDropdownProps {
  children: ReactNode;
  onClick: (e: SyntheticEvent<HTMLElement>) => void;
}

const CustomToggle = forwardRef(
  ({ children, onClick }: CustomDropdownProps, ref: Ref<HTMLLIElement>) => {
    return (
      <span
        onClick={(e) => {
          e.preventDefault();

          onClick(e);
        }}
        ref={ref}
        key={`all-categories-navbar`}
        className="all-categories-link navbar-category-link list-inline-item"
      >
        {children}
      </span>
    );
  },
);

interface CustomMenuProps {
  className?: string;
  children: ReactNode;
  style?: CSSProperties;
  'aria-labelledby'?: string;
}

const CustomMenu = forwardRef(
  (
    { children, style = {}, className = '', 'aria-labelledby': labelledBy = '' }: CustomMenuProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <div
        ref={ref}
        style={{ ...style, minWidth: '230px', maxWidth: '300px' }}
        className={className}
        aria-labelledby={labelledBy}
      >
        <ul
          className="list-unstyled mb-0"
          style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
        >
          {Children.toArray(children).sort((a: any, b: any) => {
            if (!a?.props?.category || !b?.props?.category) return 1;

            return a.props?.category?.webapp_name > b.props?.category?.webapp_name ? 1 : -1;
          })}
        </ul>
      </div>
    );
  },
);

CustomMenu.displayName = 'CustomMenu';
CustomToggle.displayName = 'CustomToggle';

interface Props {
  iconSize: string;
  label?: boolean;
  isMobile?: boolean;
}

function ActiveCategoriesList({ iconSize, label = false, isMobile = false }: Props) {
  const [show, setShow] = useState<boolean>(false);

  const { configs } = useWebContext();
  const { device } = useDevice();
  const { categories, booklets } = useNavbarContext();
  const textColor = useTextColor(
    device === 'desktop' ? configs.navbar_footer_color : configs.color,
  );

  return (
    <>
      <Dropdown
        show={show}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation();

          setShow(!show);
        }}
        onMouseEnter={() => setShow(!show)}
        onMouseLeave={() => setShow(false)}
      >
        <Dropdown.Toggle as={CustomToggle} id="all-categories-dropdown">
          <BiMenuAltLeft size={iconSize} color={textColor} />
          {label && (
            <span style={{ color: textColor }}>
              {configs.navbar_dropdown_label ?? 'Categorias'}
            </span>
          )}
        </Dropdown.Toggle>

        {categories.length > 0 && (
          <Dropdown.Menu as={CustomMenu} className="all-categories-dropdown-menu">
            {isMobile && booklets.length > 0 && (
              <BookletsLink
                color={textColor}
                style={{ backgroundColor: configs.color }}
                className="dropdown-item dropdown-item-navbar"
              />
            )}

            {categories?.map((category) => (
              <CategoryLink
                isInDropdown
                color="#333"
                category={category}
                className="dropdown-item dropdown-item-navbar"
                key={`actived-${category.type}-navbar-${category.id}`}
                type={category.type}
              />
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
}

export default ActiveCategoriesList;
