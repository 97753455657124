import './footer.scss';

import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaWhatsapp } from 'react-icons/fa';

import { useWebContext } from '@/resources/hooks/useWebContext';
import { LazyLoadImages } from '@/services/lazyLoadImage';
import { isLightOrDark } from '@/services/lightOrDark';

import {
  generateRandomKey,
  getFullAddress,
  maskDocument,
  maskPhone,
} from '../../../../services/global';
import { ISettingConfig } from '../../../../store/ducks/settings/types';
import botvendasLight from '../../../assets/images/botvendas_branco.svg';
import botvendasDark from '../../../assets/images/botvendas_cinza.svg';
import iqnusDark from '../../../assets/images/iqnus.png';
import iqnusLight from '../../../assets/images/iqnus-branco.png';
import { useDevice } from '../../../hooks/useDevice';
import { useTextColor } from '../../../hooks/useTextColor';
import { useWindowSize } from '../../../hooks/useWindowSize';
import ImageList from './ImageList';
import Socials from './Socials';

interface Props {
  configs: ISettingConfig;
}

function Footer({ configs }: Props) {
  const color = useTextColor(configs.footer.bg_color);
  const colorType = isLightOrDark(configs.footer.bg_color);
  const { device } = useDevice();
  const { width } = useWindowSize();
  const { shop } = useWebContext();
  const {
    footer: { bg_color, banner, payments, shipments, links, networks },
  } = configs;

  const whatsapp = networks.length > 0 && networks.find((n) => n.type === 'whatsapp');

  useEffect(() => {
    LazyLoadImages();
  }, [device]);

  return (
    <>
      {(banner.image_mobile || banner.image_desktop) && (
        <div className="text-center" style={{ backgroundColor: banner.bg_color }}>
          {banner.image_mobile && device === 'mobile' && (
            <img
              alt=""
              width={360}
              height={360}
              src=""
              data-src={banner.image_mobile}
              className="lazy-image text-center img-fluid"
            />
          )}

          {banner.image_desktop && device === 'desktop' && (
            <img
              alt=""
              width={1600}
              height={220}
              src=""
              data-src={banner.image_desktop}
              className="lazy-image text-center img-fluid"
            />
          )}
        </div>
      )}

      <footer className="container-fluid pt-3 pb-2" style={{ color, backgroundColor: bg_color }}>
        <Row className="justify-content-center">
          <Col className="p-lg-0 breakpoint-page" sm={12} lg={11} xl={10}>
            <Row className="justify-content-start">
              {(payments.length > 0 || shipments.length > 0) && (
                <Col md={4} className="py-1">
                  <ImageList title="Formas de pagamento" list={payments} />
                  <ImageList title="Formas de envio" list={shipments} />
                </Col>
              )}

              <Col md={3} className="py-1 mb-2 mb-md-0">
                <span className="font-weight-bold" style={{ fontSize: '14pt' }}>
                  Sobre
                </span>

                <p className="mb-0 py-1">Telefone: {maskPhone(shop.whatsapp)}</p>
                <p className="mb-0 py-1">CNPJ: {maskDocument(shop.cnpj)}</p>
                <p className="mb-0 py-1 text-break">Endereço: {getFullAddress(shop.address)}</p>
              </Col>

              {links.length > 0 && (
                <Col md={3} className="py-1">
                  <span className="font-weight-bold" style={{ fontSize: '14pt' }}>
                    Links
                  </span>

                  <ul className="list-inline d-flex flex-column mb-0">
                    {links.map((link) => (
                      <li
                        key={`${generateRandomKey()}-${link.title}`}
                        className="list-inline-item py-1"
                      >
                        <a href={link.link} target="_blank" rel="noreferrer" style={{ color }}>
                          {link.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Col>
              )}

              {networks.length > 0 && (
                <Col md={2} className="py-1">
                  <span className="font-weight-bold" style={{ fontSize: '14pt' }}>
                    Redes Sociais
                  </span>

                  <Socials socials={networks} />
                </Col>
              )}
            </Row>

            <hr className="my-1 mt-1" style={{ borderColor: color }} />

            <Row>
              <Col className="text-center">
                <div className="d-block d-sm-inline-flex align-items-center my-auto">
                  <a
                    className="d-sm-inline-flex mx-2 py-1"
                    href="https://iqnus.com/botvendas/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      width={90}
                      height={17}
                      alt="Botvendas"
                      src=""
                      data-src={colorType === 'light' ? botvendasDark : botvendasLight}
                      className="lazy-image img-fluid"
                    />
                  </a>

                  <a
                    className="d-sm-inline-flex mx-2 py-1"
                    href="https://iqnus.com/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ marginBottom: '-8px' }}
                  >
                    <img
                      width={65}
                      height={21}
                      alt="Iqnus"
                      src=""
                      data-src={colorType === 'light' ? iqnusDark : iqnusLight}
                      className="lazy-image img-fluid"
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </footer>

      {whatsapp && (
        <a
          target="_blank"
          rel="noreferrer"
          href={whatsapp.link}
          className="d-flex align-items-center pl-1"
          style={{
            position: 'fixed',
            right: '1rem',
            bottom: width > 520 ? '.5rem' : '2rem',
            zIndex: 2,
            borderRadius: '8px',
            backgroundColor: '#fff',
            border: '1px solid #25d366',
          }}
        >
          <div
            className="text-center text-white px-2 mr-1"
            style={{ backgroundColor: '#25d366', borderRadius: '4px', lineHeight: 1 }}
          >
            <small className="d-block">Compre por</small>
            <strong style={{ fontSize: '14pt' }}>WhatsApp</strong>
          </div>

          <FaWhatsapp size="40" color="#25d366" />
        </a>
      )}
    </>
  );
}

export default Footer;
